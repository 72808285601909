.PlayerControlls {
    position: absolute;
    width: 98%;
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 0px;
    /* padding: 0.7rem .7%; */
    color: white;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    z-index: 3;
}

.Group{
    display: flex;
    align-items: center;
 }

 .Icon {
    margin-right: 0.3rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    display: inline-block;
    padding: 5px;
}

.volumeBar {
    margin-top: 1px;
    vertical-align: top;
    width: 80px;
    color: white !important;
}

.VolumeBar {
    background-color: var(--color3);
    height: 30%;
    width: 47px;
    margin-right: 5px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}
