.channelDot {
    position: absolute;
    top: 0;
    background: #ffffff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: 36px;
    z-index: 1;
    margin-left: -5.5px;
    border: 1px solid #014182;
    cursor: pointer;
}

.channelDot:hover {
    background: #014182;
}