.streamLineCurrentTime {
    margin-left: -25px;
    position: absolute;
    text-align: center;
    padding: 0 4px;
    -webkit-border-rader-radius: 0;
    border-radius: 0;
    background-color: #37383b;
    height: 15px;
    z-index: 11;
    top: 10px;
    pointer-events: none;
    color: #fff;
    font-family: 'geo';
    font-size: 0.7em;
    display:block;
}

.streamLineCurrentTimeArrow {
    margin-left: -6px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #37383b;
    width: 0;
    height: 0;
    position: absolute;
    top: 6px;
    z-index: 11;
    pointer-events: none;
    display: block;
}

.streamLineTitle{
    position: absolute;
    vertical-align: middle;
    text-align: center;
    padding: 4px 4px;
    background: rgba(25, 25, 25, 0.9);
    height: 60px;
    width: 150px;
    color:#fff;
    font-size: 0.75em;
    top: -64px;
    margin-left: -120px;
    font-family: 'geo';
}

.streamLineHoverTime {
    position: absolute;
    text-align: center;
    padding: 0 4px;
    padding-top: 2px;
    -webkit-border-rader-radius: 0;
    border-radius: 0;
    background-color: #37383b;
    height: 24px;
    width: 60px;
    z-index: 11;
    top: -28px;
    pointer-events: none;
    color: #fff;
    font-family: 'geo';
    font-size: smaller;
    font-weight: 200;
    margin-left: -28px;
    vertical-align: middle;
}

.streamLineHoverTimeArrow {
    border-top: 4px solid #37383b;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    width: 0;
    height: 0;
    position: absolute;
    top: -4px;
    z-index: 11;
    pointer-events: none;
    margin-left: -4px;
}

.streamLineRecOK {
    content: '';
    /* background: green; */
    position: absolute;
    top: -10;
    left: 0;
    height: 1px;
    cursor: pointer;
}

.streamLineRecOKLL {
    content: '';
    background: lightgreen;
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    cursor: pointer;
    margin-top: 2px;
}

.streamLineBlue {
    content: '';
    background: #014182;
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    cursor: pointer;
}

.streamLine {
    width: 100%;
    height: 5px;
    background: #cacaca;
    margin-top: 0px;
    position: relative;
}