@font-face {
    font-family: 'geo';
    src: url('fonts/geo.eot');
    src: url('fonts/geo.eot?#iefix') format('embedded-opentype'),
       url('fonts/geo.woff2') format('woff2'),
       url('fonts/geo.woff') format('woff'),
       url('fonts/geo.ttf') format('truetype'),
       url('fonts/geo.svg#geo') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'geoMT';
    src: url('fonts/geo_mt.eot');
    src: url('fonts/geo_mt.eot?#iefix') format('embedded-opentype'),
       url('fonts/geo_mt.woff2') format('woff2'),
       url('fonts/geo_mt.woff') format('woff'),
       url('fonts/geo_mt.ttf') format('truetype'),
       url('fonts/geo_mt.svg#geoMT') format('svg');
    font-weight: normal;
    font-style: normal;
}

::-webkit-scrollbar
{
    width: 6px;  /* for vertical scrollbars */
    height: 6px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track
{
    background: rgba(0, 50, 0, 0.1);
    
}

::-webkit-scrollbar-thumb
{
    background: rgba(1, 65, 130, 1);
}

p, input, textarea, button, select, body, #header-username {
    font-family: 'geo' !important; 
}

.link__tv {
    margin-top: 100px;
    font-size: 20;
    background-image: '/img/tvico.png';
}

.player__button {
    margin-top: 2px;
    background: transparent teal;
}

ul#vertical-sidebar-nav.sf-menu li a {
	padding: 18px 0;
}

.p0 {
	padding: 0 !important;
}

.p10 {
	padding: 0 15px !important;
}

.playerTms {
    text-align: center;
}

.time {
    flex: 1;
    position:static;
    float:left;
    width: 40px;
    min-height: 20px;
    line-height: normal;
    align-content: center;
    font-size: 12px;
}

.textdiv {
    flex: 1;
    position: static;
    float:left;
    left: 45px;
    min-height: 20px;
    line-height: normal;
    width: 240px;
    font-size: 12px;
}

.text {
    flex: 1;
    display: table-cell;
    vertical-align: middle;
    min-height: 20px;
    width: 220px;
    line-height: normal;
    font-size: 12px;
}

.chlistcol {
    padding:4px;
    /* height: 85% !important; */
    height: 660px !important;
}

.chanelfltr li {
	border-bottom: 1px solid #ddd;
	display: block;
	padding: 7px;
	background: #fff;
	border-right: 1px solid #ddd;
    position: relative;
    cursor: pointer;
}
.chanelfltr span {
    word-wrap :break-word;
}

.chanelfltr a {
	color: rgb(132, 132, 132);
	font-size: 13px;
    font-family: 'geoMT';
    display: block;
}

.epcal_main {
    width: 150px;
    display: flex;
    font-family: 'geoMT';
    align-items: center;
}

.epcal_date {
    font-size: 32pt;
    font-weight: bold;
    font-family: 'Arial, Helvetica, sans-serif';
}

.bottom__controls {
    background-color:whitesmoke;
    text-align: center;
}

.player__controls {
    margin-top: 0px;
    padding-top: 10px;
}

.timeline__controls {
    height: 5em;
    margin-top: 10px;
    margin-left: 40px;
    margin-right: 40px;
}

.showWhenBuffer {
    /* position: relative; */
    /* width: 100px;
    text-align: center; */
    opacity: 50%;
    height: 10px;
    text-align: center;
    top: 40%;
    z-index: 300;
    /* vertical-align: middle; */
}

.videoInnerControls {
    /* position: relative; */
    /* width: 100px;
    text-align: center; */
    background: red;
    opacity: 80%;
    height: 30px;
    text-align: right;
    top: 95%;
    /* vertical-align: middle; */
    z-index: 200;
}

.player__wrapper {
    width: 100%;
    /* width: 720px; */
    height: 660px;
    max-height: 660px;
    z-index: 100;
}

.mainMenuCol {
    background-color:whitesmoke;
    /* width: 100%; */
}

.channelListCol {
    background-color:whitesmoke;
    padding: 0 !important;
    margin: 0 !important;
    /* max-width: 80px; */
}

.playerCol {
    background-color:black;
    padding: 0 !important;
    margin: 0 !important;
}

.epgListCol {
    background-color:whitesmoke;
}

.hoursDisplay {
    margin-left: -15px;
    position: absolute;
    top: 2px;
    color: #919191;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 400;
    margin-top: -3px;
    font-size: 13px;
    text-align: right;
}

.ruller svg {
    width: 100%;
    height: 15px;
}

.ruller {
    background: #fff;
    border-top: 1px solid #ccc;
    padding: 15px 0;
    position: relative;
}

.posDisplay {
    margin-left: -28px;
    position: absolute;
    padding: 0px 3px 0px 3px;
    top: 28px;
    background: black;
    color: white;
    font-family: monospace;
    font-size: 8px;
    font-weight: 400;
    margin-top: -3px;
    font-size: 13px;
}

.horFrame1 {
    max-height: 600px;
}

.mycol-1 {
    float:left;
    width: 120px;
    /* max-width: 5%; */
}

.mycol-2 {
    float:left;
    width: 250px;
    /* max-width: 20%; */
}

.mycol-3 {
    float: left;
    width: 1174px;
    /* margin-left: 15px; */
    /* max-width: 70%; */
}

.mycol-4 {
    /* position: absolute; */
    float:right;
    width: 340px;
    /* max-width: 20%; */
}

.myrow-2:after {
    content: "";
    display: table;
    clear: both;
  }