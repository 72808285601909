#ChannelsListContainer ul li:hover {
    color: #014182;
    font-weight: bold;
    background-color:lightblue;
}

.channelfilter {
    margin-bottom: 0 !important;
    /* height: 95% !important; */
    /* height: 660px !important; */
    max-height: 660px;
    overflow-y: scroll;
    scrollbar-color: #0A4C95 #C2D2E4;
    scrollbar-width: thin;
}

.channelfilter a {
    color: rgb(132, 132, 132);
    font-size: 13px;
    font-family: 'geoMT';
    display: block;
}

.channelfilter li {
	border-bottom: 1px solid #ddd;
	display: block;
	padding: 7px;
	background: #fff;
	border-right: 1px solid #ddd;
    position: relative;
    cursor: pointer;
}

.channelfilter span {
    word-wrap :break-word;
}

.channel-list {
    height: 70%;
    /* height: 660px; */
    border: #000;
}

.active__channel {
	background: lightgray !important;
    font-weight: bold;
    color: #014182;
    text-decoration:none; 
    outline: none;
}

.passive__channel {
    color: #014182 !important;
    font-weight: bold;
    text-decoration:none; 
    outline: none;
}
