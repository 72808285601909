#EpgListContainer
{
    margin-bottom: 0px !important;
    height:660px !important;
    display:block;
}

#EpgListContainer ul {
    /* font-family: 'geoMT'; */
    font-family: 'geo';
    margin-bottom: 0 !important;
    height: 660px !important;
}
#EpgListContainer ul li {
    position:relative;
    float: left;
    width: 100%
}

#EpgListContainer ul li:hover {
    color: #014182;
    font-weight: bold;
    background-color:lightblue;
}

.epgfilter {
    margin-bottom: 0 !important;
    /* height: 95% !important; */
    height: 660px !important;
    overflow-y: scroll;
    scrollbar-color: #0A4C95 #C2D2E4;
    scrollbar-width: thin;
    padding-left: 0px;
    border: 0px;
}

.epgfilter a {
	color: rgb(132, 132, 132);
	font-size: 13px;
    font-family: 'geo';
    display: block;
    text-decoration: none;
}

.epgfilter span {
    word-wrap :break-word;
}

.epgfilter li {
	border-bottom: 1px solid #ddd;
	display: block;
	padding: 6px;
	/* background: #fff; */
	border-right: 1px solid #ddd;
    position: relative;
    cursor: pointer;
}

.active__epg {
	/* background:rebeccapurple; */
    font-weight: bold;
    color: #014182;
}

.active__epgli {
	background:lightgray;
    font-weight: bold;
    color: #014182;
}

.passed__epg {
    font-weight: bold;
    color: #353434;
}

.next__epg {
    font-weight: normal;
    color: #807d7d;
}
