.epg-calendar-item {
    height: 4.0625em;
    width: 180px;
    padding: 0 .9375em;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    text-align: left;
    color: #014182;
    font-family: 'geoMT';
    margin: 2px;
    background-color: lightgray;
}

.epg-calendar-item-active {
    height: 4.0625em;
    width: 180px;
    padding: 0 .9375em;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    text-align: left;
    color: #014182;
    font-family: 'geoMT';
    margin: 2px;
    background-color: gray;
}

/* .epg-calendar-item *:hover {
    background-color: lightblue!important;
    color: black !important;
} */

#EpgCalendar {
    list-style: none;
    font-family: 'geoMT';
    margin-bottom: 0 !important;
}

#EpgCalendar ul {
    font-family: 'geoMT';
    margin-bottom: 0 !important;
}

#EpgCalendar li {
    display: inline-block;
    position: relative;
    border-left: 1px solid lightgray;
    /* font-weight: 500; */
    /* background-color: lightgray; */
}

#EpgCalendar li:hover {
    background-color: lightblue!important;
    /* color: white !important; */
    font-weight: bold;
}

/* #EpgCalendar li *:hover {
    background-color: lightblue!important;
    color: white !important;
} */

/* #EpgCalendar ul li:hover {
    color: #014182;
    font-weight: bold;
    background:lightblue;
} */

/* #EpgCalendar li span {
    padding-left: 7px !important;
    text-align: left !important;
    background-color: yellow;
} */

/* #EpgCalendar li:last-of-type {
    border-right: 1px solid lightgray;
} */

.epg-calendar-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    height: 100%;
}

.date-block {
    transition: color .3s ease-in-out;
    font-size: 3em;
    font-family: 'geoMT'    
}

.date-text {
    transition: color .3s ease-in-out;
    /* width: calc(100% - 4.2em); */
    /* font-family: DejaVuSans,sans-serif; */
    width: 100px;
    font-size: .9em;
    padding-left: 5px;
    line-height: 1.2em;
    color: #014182;
    margin-left: .71428571em;
    box-sizing: 100px 100px;
    padding-top: 6px;
    /* font-weight: 400; */
}

.day {
    font-size: 0.95em;
    text-align: center;
    height: 1.6em;
}

.month {
    font-size: 0.95em;
    text-align: center;
    height: 1.6em;
}
