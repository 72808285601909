.myBtn {
    padding: 5px 10px;
    color: teal;
    font-size: 14px;
    background: transparent;
    border: 1px solid teal;
    cursor: pointer;
}

.myBtnRewind {
    padding: 5px 10px;
    color: teal;
    font-size: 14px;
    background: lightcyan;
    border: 1px solid teal;
    cursor: pointer;
    border-radius: 5px;
}
.myBtnRewind:hover {
    color: white;
    background-color: blue;
}

.myBtnPlayPause {
    padding: 10px 15px;
    color: teal;
    font-size: 20px;
    background: lightcyan;
    border: 1px solid teal;
    cursor: pointer;
    border-radius: 5px;
}
.myBtnPlayPause:hover {
    color: white;
    background-color: blue;
}